import { ReportConfiguration } from "@/reports/ReportModel";
import { ProjectListReportConfig as TeamProjectListReportConfig } from "@/reports/team/src/ProjectListReportConfig";
import {
  ProjectsWithoutLiaisonsReportConfig as TeamProjectsWithoutLiaisonsReportConfig
} from "@/reports/team/src/ProjectsWithoutLiaisonsReportConfig";
import { DataListReportConfig as TeamDataListReportConfig } from "@/reports/team/src/DataListReportConfig";
import { LiaisonListPEO603ReportConfig } from "@/reports/team/src/LiaisonListPEO603ReportConfig";

import { ProjectListReportConfig } from "@/reports/organization/src/ProjectListReportConfig";
import { ProjectsWithoutLiaisonsReportConfig } from "@/reports/organization/src/ProjectsWithoutLiaisonsReportConfig";
import {
  ProjectWithoutStudentListReportConfig
} from "@/reports/organization/src/ProjectWithoutStudentListReportConfig";
import {
  ProjectParticipantMailingListReportConfig
} from "@/reports/organization/src/ProjectParticipantMailingListReportConfig";
import {
  ProjectParticipantMailingListReportConfig as TeamProjectParticipantMailingListReportConfig
} from "@/reports/team/src/ProjectParticipantMailingListReportConfig";
import { TeamMailingListReportConfig } from "@/reports/organization/src/TeamMailingListReportConfig";
import { LiaisonListPEO604ReportConfig } from "@/reports/organization/src/LiaisonListPEO604ReportConfig";
import { AccessAuditReportConfig } from "@/reports/organization/src/AccessAuditReportConfig";
import { DataListReportConfig } from "@/reports/organization/src/DataListReportConfig";
import { SCH101InternshipsReportConfig } from "@/reports/organization/src/SCH101Internships";
import { SCH102InternshipsPerProgramReportConfig } from "@/reports/organization/src/SCH102InternshipsPerProgram";
import { SCH103FirstHires } from "@/reports/organization/src/SCH103FirstHires";
import { SCH104FirstHiresPerProgram } from "@/reports/organization/src/SCH104FirstHiresPerProgram";
import { SCH205SuperStudentParticipation } from "@/reports/team/src/SCH205SuperStudentParticipation";
import { FirstHires452MReportConfig } from "@/reports/team/src/FirstHires452M";
import { Internships453MReportConfig } from "@/reports/team/src/Internships453M";
import { TSK203AllTasks } from "@/reports/organization/src/task/TSK203AllTasks";
import { TSK202CompleteTasks } from "@/reports/organization/src/task/TSK202CompleteTasks";
import { TSK201OverdueTasks } from "@/reports/organization/src/task/TSK201OverdueTasks";
import { DAT302PublicationCompliance } from "@/reports/organization/src/DAT302PublicationCompliance";
import { ScholarProjectParticipation407Config } from "@/reports/organization/src/ScholarProjectParticipation407Report";
import { ScholarDegree406Config } from "@/reports/organization/src/ScholarDegree406Report";
import { ScholarProjectParticipation457Config } from "@/reports/organization/src/ScholarProjectParticipation457Report";
import { ScholarDegree456Config } from "@/reports/organization/src/ScholarDegree456Report";
import { Tasks551MReportConfig } from "@/reports/team/src/Tasks551MReportConfig";
import { UndergraduateProjectTeamReportConfig } from "@/reports/team/demo/UndergraduateProjectTeamReportConfig";

export const reportConfigurations: ReportConfiguration[] = [
  ProjectListReportConfig,
  ProjectsWithoutLiaisonsReportConfig,
  ProjectWithoutStudentListReportConfig,
  ProjectParticipantMailingListReportConfig,
  UndergraduateProjectTeamReportConfig,
  TeamProjectParticipantMailingListReportConfig,
  TeamMailingListReportConfig,
  LiaisonListPEO604ReportConfig,
  AccessAuditReportConfig,
  DataListReportConfig,
  TeamProjectListReportConfig,
  TeamProjectsWithoutLiaisonsReportConfig,
  LiaisonListPEO603ReportConfig,
  TeamDataListReportConfig,
  SCH101InternshipsReportConfig,
  SCH102InternshipsPerProgramReportConfig,
  SCH103FirstHires,
  SCH104FirstHiresPerProgram,
  FirstHires452MReportConfig,
  Internships453MReportConfig,
  SCH205SuperStudentParticipation,
  TSK203AllTasks,
  Tasks551MReportConfig,
  TSK202CompleteTasks,
  TSK201OverdueTasks,
  DAT302PublicationCompliance,
  ScholarProjectParticipation407Config,
  ScholarDegree406Config,
  ScholarProjectParticipation457Config,
  ScholarDegree456Config
];
