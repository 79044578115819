import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition
} from "@/reports/ReportModel";
import { MvProjectLinkAccessor, userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["MvStudents.countryList"],
  order: {
    "MvStudents.createdAt": "desc",
  },
  filters: [
    {
      member: "MvStudents.ProjectParticipationUserCategoryCode",
      operator: "equals",
      values: ["student"],
    },
  ],
  dimensions: [
    // "MvStudents.TenantId",
    "MvStudents.hash", // Required for LinkAccessor
    "MvStudents.pronoun",
    "MvStudents.participateInAnNonEndedProject",
    "MvStudents.hired",
    "MvStudents.email",
    "MvStudents.expiresAt",
    // "MvProjects.id", // Required for LinkAccessor
    "MvStudents.availableForHireAt",
    "MvStudents.availableForInternshipAt",
    "MvStudents.name",
    "MvStudents.universityName",
    "MvStudents.gender",
    "MvStudents.race",
    "MvStudents.visa",
    "MvStudents.lawfulPermanentResidencyStatus",
    "MvStudents.exportControlStatus",
    "MvStudents.ProjectParticipationDegreeDegree",
    "MvStudents.ProjectParticipationDegreeField",
    "MvStudents.ProjectParticipationDegreeGraduationDate",
    "MvStudents.BusinessEntity",
    "MvStudents.Center",
    "MvStudents.SeismicShift",
    "MvStudents.Thrust",
    "MvStudents.Subthrust",
    "MvStudents.Theme",
    "MvStudents.Area",
    "MvStudents.ResearchScholar",
    "MvStudents.ProjectHash",
    "MvStudents.ProjectBusinessId",
    "MvStudents.ProjectName",
    "MvStudents.ProjectStartsAt",
    "MvStudents.ProjectEndsAt",
    "MvStudents.hasPrivateProfile",
    "MvStudents.ResumeName",
    "MvStudents.ResumeId",
    "MvStudents.exitStatus",
  ],
  // segments: ["MvStudents.onlyWithProjectParticipationDegree"],
  segments: ["MvStudents.userNotDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MvStudents.exitStatus",
    label: "Student Exit Status",
  },
  {
    dimension: "MvStudents.gender",
    label: "Gender",
  },
  {
    dimension: "MvStudents.race",
    label: "Ethnicity",
  },
  {
    dimension: "MvStudents.country",
    label: "Citizenship",
  },
  {
    dimension: "MvStudents.visa",
    label: "US Visa Status",
  },
  {
    label: "Export Control Status",
    dimension: "MvStudents.exportControlStatus",
  },
  {
    dimension: "MvStudents.ProjectParticipationDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "MvStudents.ProjectParticipationDegreeField",
    label: "Degree Field",
  },
  {
    dimension: "MvStudents.availableForHireAt",
    label: "Available for hire",
  },
  {
    dimension: "MvStudents.availableForInternshipAt",
    label: "Available for internship",
  },
];

// order is important
const columns: ReportColumn[] = [
  // {
  //   title: "USER MVID",
  //   key: "MvStudents.mvid",
  //   type: ReportColumnType.Numeric
  // },
  {
    title: "Student ID",
    key: "MvStudents.hash",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Student name",
    key: "MvStudents.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder("MvStudents.hash"),
  },
  {
    title: "Student email",
    key: "MvStudents.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Student University",
    key: "MvStudents.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Resume",
    key: "MvStudents.ResumeName",
    fileIdMember: "MvStudents.ResumeId",
    type: ReportColumnType.File,
  },
  {
    title: "Gender",
    key: "MvStudents.gender",
    type: ReportColumnType.Text,
  },
  {
    title: "Preferred Pronoun",
    key: "MvStudents.pronoun",
    type: ReportColumnType.Text,
  },
  {
    title: "Ethnicity",
    key: "MvStudents.race",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Type",
    key: "MvStudents.ProjectParticipationDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "MvStudents.ProjectParticipationDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Available for hire",
    key: "MvStudents.availableForHireAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Available for internship",
    key: "MvStudents.availableForInternshipAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Degree Graduation Date",
    key: "MvStudents.ProjectParticipationDegreeGraduationDate",
    type: ReportColumnType.Date,
  },
  {
    title: "Hired",
    key: "MvStudents.hired",
    type: ReportColumnType.Boolean,
    labels: { true: "Yes", false: "No" },
  },
  {
    title: "Citizenships",
    key: "MvStudents.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "Green Card",
    key: "MvStudents.lawfulPermanentResidencyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "MvStudents.exportControlStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "MvStudents.BusinessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "MvStudents.SeismicShift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "MvStudents.Thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "MvStudents.Subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "MvStudents.Theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "MvStudents.Center",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "MvStudents.Area",
    type: ReportColumnType.Text,
  },
  {
    title: "Research Scholar",
    key: "MvStudents.ResearchScholar",
    type: ReportColumnType.Text,
  },
  {
    title: "Active Participation",
    key: "MvStudents.participateInAnNonEndedProject",
    type: ReportColumnType.Boolean,
    labels: { true: "Yes", false: "No" },
  },
  {
    title: "Project ID",
    key: "MvStudents.ProjectBusinessId",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Project Pillar ID",
    key: "MvStudents.ProjectHash",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Project Name",
    key: "MvStudents.ProjectName",
    type: ReportColumnType.Link,
    linkAccessor: MvProjectLinkAccessor,
  },
  {
    title: "Project Start Date",
    key: "MvStudents.ProjectStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Projects End Date",
    key: "MvStudents.ProjectEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Private Profile",
    key: "MvStudents.hasPrivateProfile",
    type: ReportColumnType.Boolean,
    labels: { true: "Yes", false: "No" },
  },
  {
    title: "Exit Status",
    key: "MvStudents.exitStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "User Expiration Date",
    key: "MvStudents.expiresAt",
    type: ReportColumnType.Date,
  },
];

export const ScholarDegree406Config: ReportConfiguration = {
  name: "Scholar Degree (406)",
  section: "Student",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
  zipExport: true,
};
