import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope
} from "@/reports/ReportModel";
import { ProjectLeaderLinkAccessor, ProjectLinkAccessor, userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Citizenships.countryList"],
  order: {
    "Users.createdAt": "desc",
  },
  filters: [
    {
      member: "HiresPerProgram.ProjectParticipationType",
      operator: "equals",
      values: ["student"],
    },
  ],
  dimensions: [
    "Users.hash", // Required for LinkAccessor
    "Users.pronoun",
    "Projects.hash", // Required for LinkAccessor
    "ProjectLeader.hash", // Required for LinkAccessor
    "HiresPerProgram.email",
    "HiresPerProgram.availableForHireAt",
    "HiresPerProgram.availableForInternshipAt",
    "HiresPerProgram.linkedInId",
    "HiresPerProgram.name",
    "HiresPerProgram.universityName",
    "HiresPerProgram.visa",
    "HiresPerProgram.lawfulPermanentResidencyStatus",
    "HiresPerProgram.exportControlStatus",
    "HiresPerProgram.current",
    "HiresPerProgram.exitStatus",
    "HiresPerProgram.BusinessEntity",
    "HiresPerProgram.Center",
    "HiresPerProgram.SeismicShift",
    "HiresPerProgram.Thrust",
    "HiresPerProgram.Subthrust",
    "HiresPerProgram.Theme",
    "HiresPerProgram.Area",
    "HiresPerProgram.ResearchScholar",
    "HiresPerProgram.ProjectId",
    "HiresPerProgram.ProjectBusinessId",
    "HiresPerProgram.ProjectName",
    "HiresPerProgram.ProjectLeaderName",
    "HiresPerProgram.ProjectLeaderId",
    "HiresPerProgram.ProjectStartsAt",
    "HiresPerProgram.ProjectEndsAt",
    "HiresPerProgram.hasPrivateProfile",
    "Resumes.name",
    "Resumes.id",
  ],
  segments: [
    "Users.notDeleted",
    "Resumes.notDeleted",
    "Projects.notDeleted",
    "ProjectLeader.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "HiresPerProgram.name",
    label: "User Name",
  },
  {
    dimension: "HiresPerProgram.current",
    label: "Current",
  },
  {
    dimension: "HiresPerProgram.exitStatus",
    label: "Student Exit Status",
  },
  {
    dimension: "Citizenships.country",
    label: "Citizenship",
  },
  {
    dimension: "HiresPerProgram.visa",
    label: "US Visa Status",
  },
  {
    label: "Export Control Status",
    dimension: "HiresPerProgram.exportControlStatus",
  },
  {
    dimension: "HiresPerProgram.availableForHireAt",
    label: "Available for hire",
  },
  {
    dimension: "HiresPerProgram.availableForInternshipAt",
    label: "Available for internship",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "Users.hash",
    type: ReportColumnType.Text,
  },
  {
    title: "Student name",
    key: "HiresPerProgram.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
  },
  {
    title: "Student E-mail",
    key: "HiresPerProgram.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Preferred Pronouns",
    key: "Users.pronoun",
    type: ReportColumnType.Text,
  },
  {
    title: "Student University",
    key: "HiresPerProgram.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Resume",
    key: "Resumes.name",
    fileIdMember: "Resumes.id",
    type: ReportColumnType.File,
  },
  {
    title: "US Visa status",
    key: "HiresPerProgram.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Citizenships",
    key: "Citizenships.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "Green Card",
    key: "HiresPerProgram.lawfulPermanentResidencyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "HiresPerProgram.exportControlStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "HiresPerProgram.BusinessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "HiresPerProgram.SeismicShift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "HiresPerProgram.Thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "HiresPerProgram.Subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "HiresPerProgram.Theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "HiresPerProgram.Center",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "HiresPerProgram.Area",
    type: ReportColumnType.Text,
  },
  {
    title: "Research Scholar",
    key: "HiresPerProgram.ResearchScholar",
    type: ReportColumnType.Text,
  },
  {
    title: "Project ID",
    key: "HiresPerProgram.ProjectBusinessId",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Project Pillar ID",
    key: "Projects.hash",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Name",
    key: "HiresPerProgram.ProjectName",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
  },
  {
    title: "Project Leader",
    key: "HiresPerProgram.ProjectLeaderName",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
  },
  {
    title: "Project Start Date",
    key: "HiresPerProgram.ProjectStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Projects End Date",
    key: "HiresPerProgram.ProjectEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Private Profile",
    key: "HiresPerProgram.hasPrivateProfile",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Exit Status",
    key: "HiresPerProgram.exitStatus",
    type: ReportColumnType.Text,
  },
];

export const ScholarProjectParticipation457Config: ReportConfiguration = {
  name: "Scholar Project Participation (457)",
  section: "Student",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
};
