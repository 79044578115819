import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "@/store";
import Dataset, { DatasetType, OrderedDataset } from "@/models/Dataset";
import IMetadata from "@/models/MetadataInterface";
import Metadata from "@/models/MetadataInterface";
import { ApiClient, ApiGeneratedClient } from "@/api";
import $router from "@/router/index";
import { IDatasetMetadata } from "@/models/DatasetMetadataInterface";
import { MimeTypeRendererRecord } from "@/mappings/datasetMimeTypeRendererMapping";
import { AmplitudeEvents } from "@/events/AmplitudeEvents";
import { EventBus } from "@/services/eventBus";
import * as Arr from "@/utils/Array";

export interface IDatasetViewState {
  currentPage: number;
  navigationLoading: boolean;
  selectedDataset: Dataset | null;
  isDatasetPreviewLoading: boolean;
  checkedDatasets: Dataset[];
  ancestors: Dataset[];
  moveModal: boolean;
  uploadModal: boolean;
  resolvedRenderer: MimeTypeRendererRecord | null;
}

const state: IDatasetViewState = {
  currentPage: 1,
  navigationLoading: false,
  selectedDataset: null,
  isDatasetPreviewLoading: false,
  checkedDatasets: [],
  ancestors: [],
  moveModal: false,
  uploadModal: false,
  resolvedRenderer: null,
};

export enum MutationTypes {
  ToggleMoveModal = "ToggleMoveModal",
  ToggleUploadModal = "ToggleUploadModal",
  RemoveDataset = "RemoveDataset",
  RemoveDatasets = "RemoveDatasets",
  AddDataset = "AddDirectory",
  GoUpOneLevel = "GoUpOneLevel",
  GoToLevel = "GoToLevel",
  SetAncestors = "SetAncestors",
  UpdateDataset = "UpdateDataset",
  SetNavigationLoading = "SetNavigationLoading",
  AddMetadata = "AddMetadata",
  SetDatasetWithAncestors = "SetDatasetWithAncestors",
  SetIsDatasetPreviewLoading = "SetIsDatasetPreviewLoading",
  CheckDatasets = "CheckDatasets",
  ClearSelection = "ClearSelection",
  UpdateRenderer = "UpdateRenderer",
}

const getters: GetterTree<IDatasetViewState, RootState> = {
  canGoParent: (state) => state.ancestors.length > 1,
  canGoHome: (state) => state.ancestors.length > 1,
  rootDataset: (state) => state.ancestors[0] || null,
  parentNavigationDataset: (state) =>
    state.ancestors[state.ancestors.length - 2] || null,
  navigationDataset: (state) =>
    state.ancestors[state.ancestors.length - 1] || null,
  tableData: (state, getters) => {
    if (!getters.navigationDataset || !getters.navigationDataset.children) {
      return [];
    }

    return getters.navigationDataset.children;
  },
  author: (state) => {
    if (!state.selectedDataset) {
      return null;
    }

    return state.selectedDataset.creator;
  },
  originatorName: (state) => {
    if (!state.selectedDataset) {
      return null;
    }

    return state.selectedDataset.creator.name;
  },
  protocols: (state) => {
    if (state.selectedDataset && state.selectedDataset.protocols) {
      return state.selectedDataset.protocols;
    }

    return [];
  },
  metadata: (state) => {
    const metadata: IDatasetMetadata[] = [];

    state.ancestors
      .slice(0)
      .reverse()
      .forEach((dataset) => {
        (dataset.metadata_keypairs || []).forEach((metadatum: Metadata) => {
          metadata.push({
            dataset,
            metadatum,
          });
        });
      });

    if (state.selectedDataset && state.selectedDataset.metadata_keypairs) {
      state.selectedDataset.metadata_keypairs.forEach((metadatum) => {
        if (state.selectedDataset) {
          metadata.push({
            dataset: state.selectedDataset,
            metadatum,
          });
        }
      });
    }

    return metadata;
  },
};

function parseHash() {
  try {
    const infos = JSON.parse(atob(location.hash.substr(1)));

    if (infos.dataset_id) {
      return infos.dataset_id;
    }

    return null;
  } catch (e) {
    // Is not a usable hash for datasetView
  }

  return null;
}

export function createHashForDatasetId(datasetId: number, page = 1): string {
  const hash = {
    dataset_id: datasetId,
    page,
  };
  return btoa(JSON.stringify(hash));
}

const actions: ActionTree<IDatasetViewState, RootState> = {
  async goToLevel(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    index: number
  ) {
    if (index < 0 && index >= state.ancestors.length) {
      return Promise.reject("index out of bound");
    }

    commit(MutationTypes.SetNavigationLoading, true);
    const targetDataset = state.ancestors[index];

    return ApiClient.Dataset.show(targetDataset.id).then((response) => {
      commit(MutationTypes.GoToLevel, {
        index,
        dataset: response.data,
      });
      commit(MutationTypes.SetNavigationLoading, false);
    });
  },
  async synchronize({
    commit,
    state,
    rootState,
  }: ActionContext<IDatasetViewState, RootState>) {
    commit(MutationTypes.SetNavigationLoading, true);
    // check if there is a hash
    let datasetId = parseHash();

    if (!datasetId) {
      // if no/invalid hash, load root of project
      if (!rootState.project.project) {
        return Promise.reject("Project is null");
      }

      const dataset = await ApiClient.Project.Dataset.root(
        rootState.project.project.id
      );

      datasetId = dataset.data.id;
    }

    // if hash, parse it and load the corresponding dataset
    const ancestorsRequest = ApiClient.Dataset.ancestors(datasetId, false);
    const datasetRequest = ApiClient.Dataset.show(datasetId);

    Promise.all([ancestorsRequest, datasetRequest]).then((responses) => {
      const ancestors = responses[0].data;
      const dataset = responses[1].data as OrderedDataset;

      dataset.order = ancestors.length;

      ancestors.push(dataset);

      commit(MutationTypes.SetAncestors, ancestors);
      commit(MutationTypes.SetNavigationLoading, false);
    });
  },
  deleteDataset({
    commit,
    state,
  }: ActionContext<IDatasetViewState, RootState>) {
    if (!state.selectedDataset) {
      return Promise.reject("Selected dataset is null");
    }

    EventBus.$emit(AmplitudeEvents.DatasetDeleted, { qty: 1 });

    return ApiClient.Dataset.remove(state.selectedDataset.id).then((response) =>
      commit(MutationTypes.RemoveDataset, state.selectedDataset)
    );
  },
  deleteDatasets({
    commit,
    state,
  }: ActionContext<IDatasetViewState, RootState>) {
    if (!state.checkedDatasets || state.checkedDatasets.length === 0) {
      return Promise.reject("Checked dataset is null");
    }

    EventBus.$emit(AmplitudeEvents.DatasetDeleted, {
      qty: state.checkedDatasets.length,
    });

    return ApiGeneratedClient.deleteDatasets({
      inlineObject: {
        datasets: state.checkedDatasets.map((d) => d.id),
      },
    }).then(() => commit(MutationTypes.RemoveDatasets, state.checkedDatasets));
  },
  renameDataset(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    name
  ): Promise<void> {
    if (!state.selectedDataset) {
      return Promise.reject("Selected dataset is null");
    }

    return ApiClient.Dataset.update(state.selectedDataset.id, {
      name,
    }).then((response) =>
      commit(MutationTypes.UpdateDataset, {
        name,
        published_at: null,
      })
    );
  },
  editDataset(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    { name, description, category }
  ): Promise<void> {
    if (!state.selectedDataset) {
      return Promise.reject("Selected dataset is null");
    }

    return ApiClient.Dataset.update(state.selectedDataset.id, {
      name,
      description,
      category_id: category ? category.id : null,
    }).then((response) =>
      commit(MutationTypes.UpdateDataset, {
        name,
        description,
        category,
        published_at: null,
      })
    );
  },
  moveDataset(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    { target, sourceDatasets }: { target: Dataset; sourceDatasets: Dataset[] }
  ) {
    if (!sourceDatasets.length || sourceDatasets.length < 1) {
      return Promise.reject("Selected datasets list is null or empty");
    }

    return ApiClient.Dataset.moveTo(
      sourceDatasets.map((d) => d.id),
      target.id
    ).then((response) =>
      sourceDatasets.forEach((d) => commit(MutationTypes.RemoveDataset, d))
    );
  },
  intoDirectory(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    dataset: Dataset
  ): void {
    if (dataset.type === "dataset") {
      $router.push({ name: "dataset", params: { id: dataset.hash } });
      return;
    }

    const hash = {
      dataset_id: dataset.id,
    };
    location.hash = btoa(JSON.stringify(hash));
  },
  setDataset(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    dataset: Dataset
  ): Promise<void> {
    if (state.selectedDataset && dataset.id === state.selectedDataset.id) {
      return Promise.resolve();
    }
    commit(MutationTypes.SetIsDatasetPreviewLoading, true);
    commit(MutationTypes.SetDatasetWithAncestors, {
      dataset,
    });
    return ApiClient.Dataset.ancestors(dataset.id, true).then((response) => {
      if (state.selectedDataset && dataset.id === state.selectedDataset.id) {
        commit(MutationTypes.SetDatasetWithAncestors, {
          dataset,
          ancestors: response.data,
        });
        commit(MutationTypes.SetIsDatasetPreviewLoading, false);
      }
    });
  },
  checkDatasets(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    datasets: Dataset[]
  ): void {
    commit(MutationTypes.CheckDatasets, {
      datasets,
    });
  },
  createDirectory(
    { commit, state, getters }: ActionContext<IDatasetViewState, RootState>,
    name: string
  ) {
    return ApiClient.Dataset.createDirectory(getters.navigationDataset.id, {
      name,
    }).then((response) => commit(MutationTypes.AddDataset, response.data));
  },
  createDatasetExternalLink(
    { commit, getters }: ActionContext<IDatasetViewState, RootState>,
    { name, url }
  ): Promise<void> {
    return ApiClient.Dataset.create(getters.navigationDataset.id, {
      name: name,
      mime_type: "application/x-mswinurl",
      template: "url",
      content: url,
    }).then((response) => commit(MutationTypes.AddDataset, response.data));
  },
  addMetadata(
    { commit, state }: ActionContext<IDatasetViewState, RootState>,
    metadatum: IMetadata
  ): Promise<Metadata> {
    if (!state.selectedDataset) {
      return Promise.reject("selected dataset is null");
    }

    return ApiClient.Dataset.Metadata.store(
      state.selectedDataset.id,
      metadatum
    ).then((response) => {
      commit(MutationTypes.AddMetadata, response.data);

      return metadatum;
    });
  },
};

const mutations: MutationTree<IDatasetViewState> = {
  [MutationTypes.ToggleMoveModal](state: IDatasetViewState, value: boolean) {
    state.moveModal = value;
  },
  [MutationTypes.ToggleUploadModal](state: IDatasetViewState, value: boolean) {
    state.uploadModal = value;
  },
  [MutationTypes.RemoveDataset](state: IDatasetViewState, source: Dataset) {
    const current = state.ancestors[state.ancestors.length - 1] || null;

    if (!current || !current.children) {
      return;
    }

    const index = current.children.findIndex((d) => d.id === source.id);

    current.children.splice(index, 1);
    state.selectedDataset = null;
    state.checkedDatasets = [];
  },
  [MutationTypes.RemoveDatasets](state: IDatasetViewState, source: Dataset[]) {
    const current = state.ancestors[state.ancestors.length - 1] || null;

    if (!current || !current.children) {
      return;
    }

    source.forEach((dataset: Dataset) => {
      Arr.remove(current.children, (children) => children.id === dataset.id);
    });

    state.selectedDataset = null;
    state.checkedDatasets = [];
  },
  [MutationTypes.AddDataset](state: IDatasetViewState, dataset: Dataset) {
    const current = state.ancestors[state.ancestors.length - 1] || null;

    if (!current) {
      return;
    }

    let index = current.children.findIndex((d) => d.id === dataset.id);

    if (index >= 0) {
      current.children.splice(index, 1, dataset);
    } else {
      // Insert after directories if there are directories, else insert first as first element
      index = Math.max(
        current.children.findIndex((d) => d.type === DatasetType.Dataset),
        0
      );

      current.children.splice(index, 0, dataset);
    }
  },
  [MutationTypes.GoUpOneLevel](state: IDatasetViewState) {
    state.ancestors.pop();
    window.location.hash = createHashForDatasetId(
      state.ancestors[state.ancestors.length - 1].id
    );
  },
  [MutationTypes.SetAncestors](state: IDatasetViewState, ancestors: Dataset[]) {
    state.ancestors = ancestors;
    state.selectedDataset = null;
    state.checkedDatasets = [];
  },
  [MutationTypes.SetIsDatasetPreviewLoading](
    state: IDatasetViewState,
    isLoading: boolean
  ) {
    state.isDatasetPreviewLoading = isLoading;
  },
  [MutationTypes.UpdateDataset](
    state: IDatasetViewState,
    dataset: Partial<Dataset>
  ) {
    if (!state.selectedDataset) {
      return;
    }

    Object.assign(state.selectedDataset, dataset);
  },
  [MutationTypes.GoToLevel](
    state: IDatasetViewState,
    payload: { index: number; dataset: Dataset }
  ) {
    state.ancestors = state.ancestors.slice(0, payload.index);
    state.ancestors.push(payload.dataset);
    state.currentPage = 1;
    window.location.hash = createHashForDatasetId(payload.dataset.id);
  },
  [MutationTypes.SetNavigationLoading](
    state: IDatasetViewState,
    value: boolean
  ) {
    state.navigationLoading = value;
  },
  [MutationTypes.ClearSelection](state: IDatasetViewState) {
    state.selectedDataset = null;
    state.checkedDatasets = [];
  },
  [MutationTypes.SetDatasetWithAncestors](
    state: IDatasetViewState,
    payload: { dataset: Dataset; ancestors?: Dataset[] }
  ) {
    if (payload.ancestors) {
      Object.assign(
        payload.dataset,
        payload.ancestors[payload.ancestors.length - 1]
      );
    }

    state.selectedDataset = payload.dataset;
  },
  [MutationTypes.CheckDatasets](
    state: IDatasetViewState,
    payload: { datasets: Dataset[] }
  ) {
    state.checkedDatasets = payload.datasets;
    if (payload.datasets.length === 1) {
      state.selectedDataset = payload.datasets[0];
    }
  },
  [MutationTypes.UpdateRenderer](
    state: IDatasetViewState,
    renderer: MimeTypeRendererRecord | null
  ) {
    state.resolvedRenderer = renderer;
  },
};

export const datasetView: Module<IDatasetViewState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
