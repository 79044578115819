import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { ProjectLeaderLinkAccessor, ProjectLinkAccessor } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["AccessCategoryProject.uniqueProjectCount"],
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"],
  ],
  dimensions: [
    "Projects.businessId",
    "Projects.name",
    "ProjectLeader.participationUniversityName",
    "SrcAccessCategoryTags.businessEntity",
    "ProjectLiaisonLists.userListListWithTeam",
    "ProjectAcademicLists.userListListWithTeam",
    "ProjectStudentLists.userListListWithTeamPosition",
    "SrcAccessCategoryTags.seismicshift",
    "ProjectLeader.name",
    "Projects.startsAt",
    "Projects.endsAt",
    "Projects.endedStatus",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "Projects.hash",
    "ProjectLeader.hash",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.area",
  ],
  segments: [
    "Projects.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
    "ProjectLeader.notDeleted",
    "ProjectLiaisonLists.notDeleted",
    "ProjectAcademicLists.notDeleted",
    "ProjectStudentLists.notDeleted",
    "ProjectParticipationResearcher.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Projects.name",
    label: "Project Name",
  },
  {
    dimension: "ProjectLeader.participationUniversityName",
    label: "Project University",
  },
  {
    dimension: "Projects.businessId",
    label: "Project ID",
  },
  {
    dimension: "Projects.endedStatus",
    label: "Status",
    defaultValue: "Active",
  },
  {
    dimension: "ProjectLeader.name",
    label: "Leader",
  },
  {
    dimension: "ProjectParticipationResearcher.name",
    label: "Researcher",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Pillar ID",
    key: "Projects.hash",
    type: ReportColumnType.Text,
  },
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Text,
    width: 110,
    freeze: "Left",
  },
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Project University",
    key: "ProjectLeader.participationUniversityName",
    type: ReportColumnType.Text,
    width: 150,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
    width: 150,
  },
  {
    title: "Liaison",
    key: "ProjectLiaisonLists.userListListWithTeam",
    type: ReportColumnType.Text,
  },
  {
    title: "Academic",
    key: "ProjectAcademicLists.userListListWithTeam",
    type: ReportColumnType.Text,
  },
  {
    title: "Scholar",
    key: "ProjectStudentLists.userListListWithTeamPosition",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Status",
    key: "Projects.endedStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
];

export const ProjectListReportConfig: ReportConfiguration = {
  name: "Projects (151M)",
  section: "Project",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom,
};
