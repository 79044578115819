import { Query } from "@cubejs-client/core";
import {
  FilterVariables,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { ProjectLeaderLinkAccessor, ProjectLinkAccessor } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: [],
  timeDimensions: [],
  order: {
    "Projects.startsAt": "asc",
    "Projects.endsAt": "asc",
  },
  segments: [
    "Users.notDeleted",
    "Projects.notDeleted",
    "ProjectLeader.notDeleted",
    "ProjectParticipations.onlyActiveParticipations",
    "ProjectParticipations.onlyStateCompleted",
    "ProjectManagerLists.notDeleted",
    "MainTeams.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
  ],
  dimensions: [
    "Projects.hash",
    "Projects.name",
    "ProjectLeader.participationUniversityName",
    "Projects.startsAt",
    "Projects.endsAt",
    "Projects.businessId",
    "ProjectLeader.name",
    "Users.name",
    "MainTeams.name",
    "ProjectManagerLists.userList",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "SrcAccessCategoryTags.seismicshift",
    "SrcAccessCategoryTags.area",
    "Projects.endedStatus",
    "ProjectLeader.hash",
  ],
  filters: [
    {
      member: "ProjectParticipations.projectRole",
      operator: "equals",
      values: ["liaison"],
    },
    {
      member: "MainTeams.id",
      operator: "equals",
      values: [FilterVariables.TeamId],
    },
  ],
};

// order is important
const filterableDimensions = [
  {
    dimension: "Projects.endedStatus",
    label: "Project Status",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Project University",
    key: "ProjectLeader.participationUniversityName",
    type: ReportColumnType.Text,
    width: 150,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Text,
    width: 110,
    freeze: "Left",
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
    width: 150,
  },
  {
    title: "Liaison",
    key: "Users.name",
    type: ReportColumnType.Text,
  },
  {
    title: "Team",
    key: "MainTeams.name",
    type: ReportColumnType.Text,
  },
  {
    title: "Managers",
    key: "ProjectManagerLists.userList",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
  {
    title: "Status",
    key: "Projects.endedStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Science area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
  {
    title: "Pillar ID",
    key: "Projects.hash",
    type: ReportColumnType.Text,
  },
];

export const LiaisonListPEO603ReportConfig: ReportConfiguration = {
  name: "Liaisons (251M)",
  section: "People",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
