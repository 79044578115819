import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { ProjectLeaderLinkAccessor, ProjectLinkAccessor } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Projects.count"],
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"],
  ],
  filters: [
    {
      member: "ProjectUser.studentUniqueUserCount",
      operator: "equals",
      values: ["0"],
    },
  ],
  dimensions: [
    "Projects.hash",
    "Projects.businessId",
    "ProjectLeader.participationUniversityName",
    "ProjectLeader.name",
    "ProjectLeader.hash",
    "ProjectLeader.email",
    "Projects.startsAt",
    "Projects.endsAt",
    "Projects.endedStatus",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.area",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.seismicshift",
    "ProjectManagerLists.userList",
  ],
  segments: [
    "Projects.notDeleted",
    "ProjectLeader.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Projects.endedStatus",
    label: "Status",
    defaultValue: "Active",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    freeze: "Left",
  },
  {
    title: "Project University",
    key: "ProjectLeader.participationUniversityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },

  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
  },
  {
    title: "Status",
    key: "Projects.endedStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Managers",
    key: "ProjectManagerLists.userList",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
];

export const ProjectWithoutStudentListReportConfig: ReportConfiguration = {
  name: "Projects w/o Scholars (103)",
  section: "Project",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
