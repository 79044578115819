import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["MvStudents.countryList"],
  order: {
    "MvStudents.WeEndsAt": "desc",
  },
  filters: [
    {
      member: "MvStudents.ProjectParticipationUserCategoryCode",
      operator: "equals",
      values: ["student"],
    },
  ],
  dimensions: [
    "MvStudents.hash", // Required for LinkAccessor
    "MvStudents.name",
    "MvStudents.universityName",
    "MvStudents.gender",
    "MvStudents.race",
    "MvStudents.visa",
    "MvStudents.lawfulPermanentResidencyStatus",
    "MvStudents.exportControlStatus",
    "MvStudents.exitStatus",
    "MvStudents.WeCompanyName",
    "MvStudents.WeCompanyCategory",
    "MvStudents.WeStartsAt",
    "MvStudents.WeEndsAt",
    "MvStudents.WeDegreeDegree",
    "MvStudents.WeDegreeField",
    "MvStudents.WeDegreeGraduationDate",
    "MvStudents.BusinessEntity",
    "MvStudents.Center",
    "MvStudents.SeismicShift",
    "MvStudents.Thrust",
    "MvStudents.Subthrust",
    "MvStudents.Theme",
    "MvStudents.Area",
    "MvStudents.country",
  ],
  segments: ["MvStudents.onlyHires"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MvStudents.name",
    label: "User Name",
  },
  {
    dimension: "MvStudents.WeCompanyName",
    label: "Hire Organization",
  },
  {
    dimension: "MvStudents.WeCompanyCategory",
    label: "Hire Category",
  },
  {
    dimension: "MvStudents.gender",
    label: "Gender",
  },
  {
    dimension: "MvStudents.race",
    label: "Ethnicity",
  },
  {
    dimension: "MvStudents.country",
    label: "Citizenship",
  },
  {
    dimension: "MvStudents.visa",
    label: "US Visa Status",
  },
  {
    label: "Export Control Status",
    dimension: "MvStudents.exportControlStatus",
  },
  {
    dimension: "MvStudents.WeDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "MvStudents.WeDegreeField",
    label: "Degree Field",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "MvStudents.hash",
    type: ReportColumnType.Text,
  },
  {
    title: "Student name",
    key: "MvStudents.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder("MvStudents.hash"),
  },
  {
    title: "Student University",
    key: "MvStudents.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Hire Organization",
    key: "MvStudents.WeCompanyName",
    type: ReportColumnType.Text,
  },
  {
    title: "Hire Date (From)",
    key: "MvStudents.WeStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Hire Date (To)",
    key: "MvStudents.WeEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Hire Category",
    key: "MvStudents.WeCompanyCategory",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Type",
    key: "MvStudents.WeDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "MvStudents.WeDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Graduation Date",
    key: "MvStudents.WeDegreeGraduationDate",
    type: ReportColumnType.Date,
  },
  {
    title: "Citizenships",
    key: "MvStudents.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "Gender",
    key: "MvStudents.gender",
    type: ReportColumnType.Text,
  },
  {
    title: "Ethnicity",
    key: "MvStudents.race",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Green Card",
    key: "MvStudents.lawfulPermanentResidencyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "MvStudents.exportControlStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "MvStudents.BusinessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "MvStudents.SeismicShift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "MvStudents.Thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "MvStudents.Subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "MvStudents.Theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "MvStudents.Center",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "MvStudents.Area",
    type: ReportColumnType.Text,
  },
  {
    title: "Exit Status",
    key: "MvStudents.exitStatus",
    type: ReportColumnType.Text,
  },
];

export const SCH104FirstHiresPerProgram: ReportConfiguration = {
  name: "First Hires by Program (404)",
  section: "Student",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "MvStudents.WeEndsAt",
    endsAt: "MvStudents.WeEndsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
