import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { ProjectLeaderLinkAccessor, ProjectLinkAccessor } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Projects.count", "Tasks.progression"],
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"],
  ],
  dimensions: [
    "Projects.hash",
    "Projects.businessId",
    "Projects.name",
    "Projects.status",
    "Projects.updatedAt",
    "ProjectLeader.hash",
    "ProjectLeader.name",
    "ProjectLeader.email",
    "Projects.startsAt",
    "Projects.endsAt",
    "AccessCategoryProject.accessCategoriesNameAggregate",
  ],
  filters: [
    {
      member: "AccessCategoryProject.accessCategoriesNameAggregate",
      operator: "contains",
      values: ["Undergraduate Research Project"],
    },
  ],
  segments: [
    "Projects.notDeleted",
    "ProjectLeader.notDeleted",
    "AccessCategoryProject.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Projects.status",
    label: "Status",
  },
  {
    dimension: "ProjectLeader.name",
    label: "Student Name",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Text,
    width: 110,
    freeze: "Left",
  },
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Status",
    key: "Projects.status",
    type: ReportColumnType.Text,
  },
  {
    title: "Progression %",
    key: "Tasks.progression",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
    width: 150,
  },
  {
    title: "Last Update",
    key: "Projects.updatedAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Student Name",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
    width: 150,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
];

export const UndergraduateProjectTeamReportConfig: ReportConfiguration = {
  name: "Undergraduate Projects (Team)",
  section: "Project",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom,
};
