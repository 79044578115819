import { RawLocation } from "vue-router";

export const ProjectLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_overview",
  params: { id: row["Projects.hash"].toString() },
});

export const MvProjectLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_overview",
  params: { id: row["MvStudents.ProjectHash"].toString() },
});

export const ProjectLeaderLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "user_profile",
  params: { user_id: row["ProjectLeader.hash"].toString() },
});

export const userLinkAccessorBuilder: (
  userIdDimension: string = "Users.hash"
) => (row: Record<string, string | number>) => RawLocation =
  (userIdDimension = "Users.hash") =>
  (row) => ({
    name: "user_profile",
    params: { user_id: row[userIdDimension].toString() },
  });

export const TaskLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_tasks",
  params: { id: row["Projects.hash"].toString() },
  query: { task_id: row["Tasks.hash"].toString() },
});
