import Vue from "vue";
import Router, { Route } from "vue-router";
import store from "@/store/index";
import adminRoutes from "./admin";
import authRoutes from "./auth";
import datasetRoutes from "./dataset";
import protocolRoutes from "./protocol";
import teamRoutes from "./team";
import organizationRoutes from "./organization";
import projectRoutes from "./project";
import searchRoutes from "./search";
import specialRoutes from "./special";
import tasksRoutes from "./task";
import settingsRoutes from "./settings";
import userRoutes from "./user";
import publicationRoutes from "./publication";
import demoRoutes from "./demo";
import fileRoutes from "./file";
import { MutationTypes as ApplicationMutationTypes } from "@/store/modules/application";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/vue";
import theme from "@/assets/theme.scss";
import { authenticationPromise } from "@/services/Auth";
import { isAuth0Callback } from "@/config/auth0";
import UserPermissions from "@/models/UserPermissions";
import { configuration } from "@/config/dynamic";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    ...authRoutes,
    ...searchRoutes,
    ...protocolRoutes,
    ...datasetRoutes,
    ...projectRoutes,
    ...teamRoutes,
    ...organizationRoutes,
    ...adminRoutes,
    ...specialRoutes,
    ...settingsRoutes,
    ...tasksRoutes,
    ...userRoutes,
    ...fileRoutes,
    ...publicationRoutes,
    ...demoRoutes,
  ],
});

const PENDING_ROUTING_KEY = "pendingRouting";

router.beforeEach(async (to, from, next) => {
  if (["/callback", "/"].includes(to.path)) {
    // These are special routes. Always free to go there and bypass any other logic
    next();
    return;
  }

  if (store.state.application.preventReload) {
    const { value } = await Swal.fire({
      title: "Leave without saving?",
      text: "Are you sure you want to leave this page without saving your work?",
      cancelButtonText: "Stay",
      confirmButtonText: "Leave without saving",
      confirmButtonColor: theme.danger,
      showCancelButton: true,
      icon: "warning",
    });

    if (!value) {
      return;
    }

    store.commit(
      `application/${ApplicationMutationTypes.SetPreventReload}`,
      false
    );
  }

  if (store.state.application.updateAvailable) {
    sessionStorage.setItem(PENDING_ROUTING_KEY, to.fullPath);
    window.location.reload();
    return;
  }

  authenticationPromise.getInstance().then((authentication) => {
    authentication
      .onReady()
      .then(({ client }) => {
        // At this point, we know if we are authenticated or not, but
        // we don't have the user object available yet
        const item: string = sessionStorage.getItem(
          PENDING_ROUTING_KEY
        ) as string;
        if (item !== null) {
          const pendingRouting = item;

          Sentry.addBreadcrumb({
            category: "routing",
            message: "Restoring pending routing",
            level: "debug",
            data: {
              pendingRoute: pendingRouting,
              to,
              from,
            },
          });
          sessionStorage.removeItem(PENDING_ROUTING_KEY);
          if (pendingRouting.startsWith("/v2")) {
            window.location.href = pendingRouting;
          } else {
            next(pendingRouting);
          }
          return;
        }

        authentication.onUserAvailable().then(({ user }) => {
          const needsToAcceptUserAgreement =
            store.getters["user/needsToAcceptUserAgreement"];

          if (
            needsToAcceptUserAgreement &&
            to.name !== "license-agreement" &&
            !to.meta?.admin
          ) {
            Sentry.addBreadcrumb({
              category: "auth",
              message:
                "User needs to sign license agreement before using application",
              level: "debug",
              data: {
                to,
                from,
              },
            });
            next({
              name: "license-agreement",
              query: {
                pendingRoute: to.fullPath,
              },
            });
            return;
          } else if (
            needsToAcceptUserAgreement &&
            to.name === "license-agreement"
          ) {
            next();
            return;
          }

          const hasFillOnboarding = store.getters["user/hasFillOnboarding"];

          if (
            user &&
            !hasFillOnboarding &&
            to.name !== "onboarding" &&
            !to.meta?.admin
          ) {
            // sessionStorage.setItem(PENDING_ROUTING_KEY, to.fullPath);
            next({
              name: "onboarding",
              query: {
                pendingRoute: to.fullPath,
              },
            });
            return;
          }

          const hasAdminDashboardAccess =
            store.getters["user/hasAdminDashboardAccess"];
          if (to.meta?.admin && !hasAdminDashboardAccess) {
            Sentry.addBreadcrumb({
              category: "auth",
              message: "User is not allowed to access admin dashboard routes",
              level: "debug",
              data: {
                to,
                from,
              },
            });

            Sentry.captureMessage(
              "User is not allowed to access admin dashboard routes"
            );

            configuration.getInstance().then((config) => {
              next({ name: config.homeRoute });
            });
            return;
          }

          if (
            user &&
            to.meta?.permissions &&
            !to.meta.permissions.every((p: UserPermissions) =>
              user.permissions.includes(p)
            )
          ) {
            Sentry.captureMessage(
              "User does not have the required permissions to",
              {
                level: "info",
                extra: {
                  to,
                  from,
                },
              }
            );

            configuration.getInstance().then((config) => {
              next({ name: config.homeRoute });
            });
            return;
          }

          next();
        });
      })
      .catch((error) => {
        // We are not logged in. Redirect to "/" if not already going there
        if (to.name === "login" || isAuth0Callback()) {
          next();
        } else if (to.meta?.auth) {
          // Not logged in but the page requires auth. We remember the url,
          // go to login page and then restore the url from session
          // storage after login
          sessionStorage.setItem(PENDING_ROUTING_KEY, to.fullPath);

          next({ name: "login" });
        } else {
          // Not logged in and the page does not require auth, so we let it pass
          next();
        }
      });
  });
});

router.afterEach((to: Route, from: Route) => {
  Sentry.addBreadcrumb({
    category: "route",
    message: "Route changed",
    level: "info",
    data: {
      to,
      from,
    },
  });

  if (to.params && Object.keys(to.params).length > 0) {
    for (const key in to.params) {
      if (!isNaN(to.params[key])) {
        Sentry.captureException(
          new Error(`Accessed route ${to.name} using numeric params`)
        );
      }
    }
  }
});

export default router;
