import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";
import { ProjectLinkAccessor } from "@/reports/ReportLinkAccessors";

const query: Query = {
  dimensions: [
    "Datasets.hash",
    "Datasets.name",
    "Datasets.category",
    "Datasets.createdAt",
    "Publications.compliance",
    "Projects.businessId",
    "Projects.hash",
    "Projects.name",
    "Projects.endedStatus",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.area",
    "SrcAccessCategoryTags.seismicshift",
    "Publications.date",
    "DatasetAuthorLists.authorList",
  ],
  filters: [
    {
      member: "Publications.id",
      operator: "set",
    },
  ],
  segments: [
    "Datasets.notDeleted",
    "Projects.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
    "DatasetAuthorLists.notDeleted",
  ],
  ungrouped: true,
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Datasets.hash",
    label: "Dataset ID",
  },
  {
    dimension: "Datasets.name",
    label: "Dataset Name",
  },
  {
    dimension: "Projects.businessId",
    label: "Project ID",
  },
  {
    dimension: "Projects.name",
    label: "Project Name",
  },
  {
    dimension: "Teams.name",
    label: "Team",
  },
  {
    dimension: "Projects.endedStatus",
    label: "Status",
    defaultValue: "Active",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Dataset ID",
    key: "Datasets.hash",
    type: ReportColumnType.Text,
  },
  {
    title: "Dataset Name",
    key: "Datasets.name",
    type: ReportColumnType.Link,
    linkAccessor: (row) => ({
      name: "dataset",
      params: { id: row["Datasets.hash"].toString() },
    }),
  },
  {
    title: "Dataset Category",
    key: "Datasets.category",
    type: ReportColumnType.Text,
  },
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Text,
    width: 110,
    freeze: "Left",
  },
  {
    title: "Created Date",
    key: "Datasets.createdAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Published Date",
    key: "Publications.date",
    type: ReportColumnType.Date,
  },
  {
    title: "Author",
    key: "DatasetAuthorLists.authorList",
    type: ReportColumnType.Text,
  },
  {
    title: "Pre-Publication Compliance",
    key: "Publications.compliance",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
];

export const DAT302PublicationCompliance: ReportConfiguration = {
  name: "Publication Compliance (302)",
  section: "Dataset",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.Moment,
  timeDimension: "Datasets.createdAt",
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom,
};
